var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur, warning: _vm.warning } },
    [
      _vm.Accs
        ? _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "12" } }, [
                _c(
                  "h1",
                  {
                    class: [
                      `${!_vm.$screen.sm ? "main-page-title" : ""}`,
                      { "is-pwa": _vm.$isPwa() },
                    ],
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(_vm.FormMSG(215, "Accomodation")) +
                        "\n\t\t\t"
                    ),
                  ]
                ),
              ]),
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c("transition", { attrs: { name: "slide" } }, [
                    _c(
                      "div",
                      {
                        staticClass: "details-document-package form",
                        class: {
                          "container-layout": _vm.$screen.width > 576,
                          "container-mobile": _vm.$screen.width <= 576,
                          "px-0": _vm.$screen.width > 576,
                        },
                      },
                      [
                        _c(
                          "b-card",
                          { attrs: { "no-body": "" } },
                          [
                            _c(
                              "b-row",
                              {
                                staticClass:
                                  "back-with-title cursor-pointer pt-0 pb-2 pl-0",
                                class: { "mt-16": _vm.$isPwa() },
                              },
                              [
                                _c("b-col", { attrs: { cols: "6" } }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn-transparent",
                                      on: { click: _vm.goBack },
                                    },
                                    [
                                      _c(_vm.getIconLucide("ArrowLeft"), {
                                        tag: "component",
                                        staticClass: "icon",
                                        attrs: {
                                          color: "rgba(6, 38, 62, 0.65)",
                                          size: 22,
                                        },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "title-back-bolder ml-0",
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(_vm.FormMSG(163, "Back")) +
                                              "\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "b-card-text",
                              { staticClass: "px-3 pt-4" },
                              [
                                _vm.newAcc && _vm.newAcc.supplier
                                  ? _c(
                                      "b-row",
                                      [
                                        _c(
                                          "b-col",
                                          { attrs: { cols: "12", xl: "12" } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "card card-border-blue-light",
                                              },
                                              [
                                                _c(
                                                  "b-row",
                                                  [
                                                    _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "6",
                                                          lg: "6",
                                                          xl: "6",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          {
                                                            attrs: {
                                                              label:
                                                                _vm.FormMSG(
                                                                  11,
                                                                  "Name"
                                                                ),
                                                              "label-for":
                                                                "accName",
                                                            },
                                                          },
                                                          [
                                                            _c("b-form-input", {
                                                              attrs: {
                                                                type: "text",
                                                                id: "accName",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.newAcc
                                                                    .name,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.newAcc,
                                                                      "name",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "newAcc.name",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "6",
                                                          lg: "6",
                                                          xl: "6",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          {
                                                            attrs: {
                                                              label:
                                                                _vm.FormMSG(
                                                                  51,
                                                                  "Type"
                                                                ),
                                                              "label-for":
                                                                "accoType",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-select",
                                                              {
                                                                attrs: {
                                                                  id: "accoType",
                                                                  disabled:
                                                                    !_vm
                                                                      .mapAccoType
                                                                      .length,
                                                                  options:
                                                                    _vm.mapAccoType,
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.newAcc
                                                                      .type,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.newAcc,
                                                                        "type",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "newAcc.type",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-row",
                                                  [
                                                    _c(
                                                      "b-col",
                                                      { attrs: { sm: "12" } },
                                                      [
                                                        _c("SupplierSelector", {
                                                          ref: "refSupplier",
                                                          attrs: {
                                                            title: `${
                                                              !_vm.isSupplierUpdate
                                                                ? _vm.FormMSG(
                                                                    250,
                                                                    "Add supplier details"
                                                                  )
                                                                : _vm.FormMSG(
                                                                    251,
                                                                    "Update supplier details"
                                                                  )
                                                            }`,
                                                            label: _vm.FormMSG(
                                                              13,
                                                              "Name of the Hotel, Airbnb or Flying company..."
                                                            ),
                                                            addlabel:
                                                              _vm.FormMSG(
                                                                30,
                                                                "Add"
                                                              ),
                                                            "update-label":
                                                              _vm.FormMSG(
                                                                27,
                                                                "Update"
                                                              ),
                                                            placeholder:
                                                              "Filter by name",
                                                            "supplier-id":
                                                              _vm.supplierId,
                                                            type: "hotel",
                                                          },
                                                          on: {
                                                            change:
                                                              _vm.handleSupplierChange,
                                                            "supplier:unselected":
                                                              _vm.onSupplierUnselected,
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c("div", {
                                                  staticClass: "divider",
                                                }),
                                                _c(
                                                  "div",
                                                  { staticClass: "sub-title" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          31,
                                                          "Sharing options"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "b-row",
                                                  { staticClass: "pt-2" },
                                                  [
                                                    _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "6",
                                                          lg: "6",
                                                          xl: "6",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-row",
                                                          [
                                                            _c(
                                                              "b-col",
                                                              {
                                                                attrs: {
                                                                  sm: "6",
                                                                  md: "6",
                                                                  lg: "6",
                                                                  xl: "6",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "b-form-checkbox",
                                                                  {
                                                                    staticClass:
                                                                      "pj-cb pb-1",
                                                                    attrs: {
                                                                      size: "lg",
                                                                      id: "toApp",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .newAcc
                                                                          .availableOnline,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.newAcc,
                                                                            "availableOnline",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "newAcc.availableOnline",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                        _vm._s(
                                                                          _vm.FormMSG(
                                                                            12,
                                                                            "View in App"
                                                                          )
                                                                        ) +
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "b-col",
                                                              {
                                                                attrs: {
                                                                  sm: "6",
                                                                  md: "6",
                                                                  lg: "6",
                                                                  xl: "6",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "b-form-checkbox",
                                                                  {
                                                                    staticClass:
                                                                      "pj-cb pb-1",
                                                                    attrs: {
                                                                      size: "lg",
                                                                      id: "toEmail",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .newAcc
                                                                          .toEmail,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.newAcc,
                                                                            "toEmail",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "newAcc.toEmail",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                        _vm._s(
                                                                          _vm.FormMSG(
                                                                            112,
                                                                            "Send Email"
                                                                          )
                                                                        ) +
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "b-row",
                                                          [
                                                            _c(
                                                              "b-col",
                                                              {
                                                                attrs: {
                                                                  sm: "6",
                                                                  md: "6",
                                                                  lg: "6",
                                                                  xl: "6",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "b-form-checkbox",
                                                                  {
                                                                    staticClass:
                                                                      "pj-cb pb-1",
                                                                    attrs: {
                                                                      size: "lg",
                                                                      id: "toSms",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .newAcc
                                                                          .toSms,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.newAcc,
                                                                            "toSms",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "newAcc.toSms",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                        _vm._s(
                                                                          _vm.FormMSG(
                                                                            113,
                                                                            "Send Sms"
                                                                          )
                                                                        ) +
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "b-col",
                                                              {
                                                                attrs: {
                                                                  sm: "6",
                                                                  md: "6",
                                                                  lg: "6",
                                                                  xl: "6",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "b-form-checkbox",
                                                                  {
                                                                    staticClass:
                                                                      "pj-cb pb-1",
                                                                    attrs: {
                                                                      size: "lg",
                                                                      id: "toWhatsApp",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .newAcc
                                                                          .toWhatsApp,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.newAcc,
                                                                            "toWhatsApp",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "newAcc.toWhatsApp",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                        _vm._s(
                                                                          _vm.FormMSG(
                                                                            114,
                                                                            "Send WhatsApp"
                                                                          )
                                                                        ) +
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "6",
                                                          lg: "6",
                                                          xl: "6",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          {
                                                            attrs: {
                                                              "label-cols": 0,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-textarea",
                                                              {
                                                                attrs: {
                                                                  id: "description",
                                                                  placeholder:
                                                                    _vm.FormMSG(
                                                                      14,
                                                                      "description..."
                                                                    ),
                                                                  "no-resize": true,
                                                                  state:
                                                                    _vm.newAcc
                                                                      .description
                                                                      .length <
                                                                    4000,
                                                                  rows: "6",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.newAcc
                                                                      .description,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.newAcc,
                                                                        "description",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "newAcc.description",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-row",
                                                  [
                                                    _c(
                                                      "b-col",
                                                      {
                                                        staticClass: "py-2",
                                                        attrs: {
                                                          sm: "12",
                                                          md: "4",
                                                          lg: "4",
                                                          xl: "4",
                                                          "offset-md": "8",
                                                          "offset-lg": "8",
                                                          "offset-xl": "8",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-button",
                                                          {
                                                            attrs: {
                                                              variant:
                                                                "primary",
                                                              size: "md",
                                                              disabled:
                                                                this
                                                                  .disableSave,
                                                              block: "",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.curAccUpd()
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    21,
                                                                    "Save modifications"
                                                                  )
                                                                ) +
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "card card-border-blue-light",
                                              },
                                              [
                                                _c(
                                                  "b-row",
                                                  [
                                                    _c(
                                                      "b-col",
                                                      { attrs: { sm: "6" } },
                                                      [
                                                        _c("b-form-group", [
                                                          _c("h5", [
                                                            _vm._v(
                                                              _vm._s(
                                                                this
                                                                  .lastPublishedOn
                                                              )
                                                            ),
                                                          ]),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-card",
                                                  { attrs: { "no-body": "" } },
                                                  [
                                                    _c("b-card-header", [
                                                      _c("h4", [
                                                        _vm._v(
                                                          _vm._s(this.caption4)
                                                        ),
                                                      ]),
                                                    ]),
                                                    _c(
                                                      "b-card-body",
                                                      [
                                                        _c(
                                                          "b-row",
                                                          {
                                                            staticClass: "pb-2",
                                                          },
                                                          [
                                                            _c(
                                                              "b-col",
                                                              {
                                                                attrs: {
                                                                  sm: "12",
                                                                  md: "4",
                                                                  lg: "4",
                                                                  xl: "4",
                                                                  "offset-md":
                                                                    "8",
                                                                  "offset-lg":
                                                                    "8",
                                                                  "offset-xl":
                                                                    "8",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "b-button",
                                                                  {
                                                                    staticClass:
                                                                      "float-right",
                                                                    attrs: {
                                                                      variant:
                                                                        "success",
                                                                      disabled:
                                                                        _vm
                                                                          .newAcc
                                                                          .id ==
                                                                        0,
                                                                      size: "md",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.showDeliveries()
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                        _vm._s(
                                                                          _vm.FormMSG(
                                                                            25,
                                                                            "Select persons and dates..."
                                                                          )
                                                                        ) +
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "b-row",
                                                          [
                                                            _c(
                                                              "b-col",
                                                              {
                                                                staticClass:
                                                                  "hide-on-desktop",
                                                                attrs: {
                                                                  cols: "12",
                                                                  xl: "12",
                                                                },
                                                              },
                                                              [
                                                                _vm.Dlvs
                                                                  .length > 0
                                                                  ? _c(
                                                                      "CardListBuilder",
                                                                      {
                                                                        attrs: {
                                                                          items:
                                                                            _vm.Dlvs,
                                                                          fields:
                                                                            _vm.delifields,
                                                                        },
                                                                        scopedSlots:
                                                                          _vm._u(
                                                                            [
                                                                              {
                                                                                key: "actions",
                                                                                fn: function (
                                                                                  data
                                                                                ) {
                                                                                  return [
                                                                                    data
                                                                                      .item
                                                                                      .fileName
                                                                                      .length >
                                                                                    0
                                                                                      ? _c(
                                                                                          "b-button",
                                                                                          {
                                                                                            staticClass:
                                                                                              "btn bg-transparent border-0",
                                                                                            attrs:
                                                                                              {
                                                                                                variant:
                                                                                                  "primary",
                                                                                                size: "sm",
                                                                                              },
                                                                                            on: {
                                                                                              click:
                                                                                                function (
                                                                                                  $event
                                                                                                ) {
                                                                                                  return _vm.viewDocument(
                                                                                                    data.item
                                                                                                  )
                                                                                                },
                                                                                            },
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              _vm.getLucideIcon(
                                                                                                _vm
                                                                                                  .ICONS
                                                                                                  .EYE
                                                                                                  .name
                                                                                              ),
                                                                                              {
                                                                                                tag: "component",
                                                                                                attrs:
                                                                                                  {
                                                                                                    color:
                                                                                                      "#225CBD",
                                                                                                    size: 20,
                                                                                                  },
                                                                                              }
                                                                                            ),
                                                                                          ],
                                                                                          1
                                                                                        )
                                                                                      : _vm._e(),
                                                                                    _c(
                                                                                      "b-button",
                                                                                      {
                                                                                        staticClass:
                                                                                          "btn bg-transparent border-0",
                                                                                        attrs:
                                                                                          {
                                                                                            variant:
                                                                                              "danger",
                                                                                            size: "sm",
                                                                                          },
                                                                                        on: {
                                                                                          click:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              return _vm.removeUserFromDelivery(
                                                                                                data.item
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          _vm.getLucideIcon(
                                                                                            _vm
                                                                                              .ICONS
                                                                                              .TRASH
                                                                                              .name
                                                                                          ),
                                                                                          {
                                                                                            tag: "component",
                                                                                            attrs:
                                                                                              {
                                                                                                color:
                                                                                                  _vm
                                                                                                    .ICONS
                                                                                                    .TRASH
                                                                                                    .color,
                                                                                                size: 20,
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                  ]
                                                                                },
                                                                              },
                                                                            ],
                                                                            null,
                                                                            false,
                                                                            2075300456
                                                                          ),
                                                                      }
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "b-col",
                                                              {
                                                                staticClass:
                                                                  "hide-on-tablet",
                                                                attrs: {
                                                                  cols: "12",
                                                                  xl: "12",
                                                                },
                                                              },
                                                              [
                                                                _vm.Dlvs
                                                                  .length > 0
                                                                  ? _c(
                                                                      "b-table",
                                                                      {
                                                                        ref: "myTable",
                                                                        staticStyle:
                                                                          {
                                                                            "text-align":
                                                                              "left",
                                                                          },
                                                                        attrs: {
                                                                          hover:
                                                                            _vm.hover,
                                                                          responsive:
                                                                            "sm",
                                                                          "sticky-header":
                                                                            "700px",
                                                                          "head-variant":
                                                                            _vm.hv,
                                                                          selectable:
                                                                            "",
                                                                          selectedVariant:
                                                                            _vm.selectedColor,
                                                                          "select-mode":
                                                                            _vm.selectMode,
                                                                          items:
                                                                            _vm.Dlvs,
                                                                          fields:
                                                                            _vm.delifields,
                                                                          "current-page":
                                                                            _vm.currentPage,
                                                                          filter:
                                                                            _vm.filter,
                                                                          "per-page":
                                                                            _vm.perPage,
                                                                          bordered:
                                                                            "",
                                                                          small:
                                                                            "",
                                                                        },
                                                                        scopedSlots:
                                                                          _vm._u(
                                                                            [
                                                                              {
                                                                                key: "cell(status)",
                                                                                fn: function (
                                                                                  data
                                                                                ) {
                                                                                  return [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "wrap-status",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            class: `status ${data.item.validatedClass}`,
                                                                                            staticStyle:
                                                                                              {
                                                                                                "font-size":
                                                                                                  "0.7rem",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                                _vm._s(
                                                                                                  data.value
                                                                                                ) +
                                                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                },
                                                                              },
                                                                              {
                                                                                key: "cell(view)",
                                                                                fn: function (
                                                                                  data
                                                                                ) {
                                                                                  return [
                                                                                    data
                                                                                      .item
                                                                                      .fileName
                                                                                      .length >
                                                                                    0
                                                                                      ? _c(
                                                                                          "b-button",
                                                                                          {
                                                                                            staticClass:
                                                                                              "btn bg-transparent border-0",
                                                                                            attrs:
                                                                                              {
                                                                                                variant:
                                                                                                  "primary",
                                                                                                size: "sm",
                                                                                              },
                                                                                            on: {
                                                                                              click:
                                                                                                function (
                                                                                                  $event
                                                                                                ) {
                                                                                                  return _vm.viewDocument(
                                                                                                    data.item
                                                                                                  )
                                                                                                },
                                                                                            },
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              _vm.getLucideIcon(
                                                                                                _vm
                                                                                                  .ICONS
                                                                                                  .EYE
                                                                                                  .name
                                                                                              ),
                                                                                              {
                                                                                                tag: "component",
                                                                                                attrs:
                                                                                                  {
                                                                                                    color:
                                                                                                      "#225CBD",
                                                                                                    size: 20,
                                                                                                  },
                                                                                              }
                                                                                            ),
                                                                                          ],
                                                                                          1
                                                                                        )
                                                                                      : _vm._e(),
                                                                                  ]
                                                                                },
                                                                              },
                                                                              {
                                                                                key: "cell(rem)",
                                                                                fn: function (
                                                                                  data
                                                                                ) {
                                                                                  return [
                                                                                    _c(
                                                                                      "b-button",
                                                                                      {
                                                                                        staticClass:
                                                                                          "btn bg-transparent border-0",
                                                                                        attrs:
                                                                                          {
                                                                                            variant:
                                                                                              "danger",
                                                                                            size: "sm",
                                                                                          },
                                                                                        on: {
                                                                                          click:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              return _vm.removeUserFromDelivery(
                                                                                                data.item
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          _vm.getLucideIcon(
                                                                                            _vm
                                                                                              .ICONS
                                                                                              .TRASH
                                                                                              .name
                                                                                          ),
                                                                                          {
                                                                                            tag: "component",
                                                                                            attrs:
                                                                                              {
                                                                                                color:
                                                                                                  _vm
                                                                                                    .ICONS
                                                                                                    .TRASH
                                                                                                    .color,
                                                                                                size: 20,
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                  ]
                                                                                },
                                                                              },
                                                                            ],
                                                                            null,
                                                                            false,
                                                                            3258270706
                                                                          ),
                                                                      }
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-modal",
        {
          staticClass: "modal-success",
          attrs: {
            "header-class": "header-class-modal-doc-package",
            title: _vm.FormMSG(22, "Success!"),
            "ok-variant": "success",
            "ok-only": "",
            "modal-class": "mui-animation",
            fade: false,
          },
          on: {
            ok: function ($event) {
              _vm.successModal = false
            },
          },
          model: {
            value: _vm.successModal,
            callback: function ($$v) {
              _vm.successModal = $$v
            },
            expression: "successModal",
          },
        },
        [
          _vm._v(
            "\n\t\t" +
              _vm._s(this.FormMSG(23, "The new accomodation has been saved.")) +
              "\n\t"
          ),
        ]
      ),
      _c(
        "b-modal",
        {
          staticClass: "modal-success",
          attrs: {
            "header-class": "header-class-modal-doc-package",
            title: _vm.FormMSG(22, "Success!"),
            "ok-variant": "success",
            "ok-only": "",
            "modal-class": "mui-animation",
            fade: false,
          },
          on: {
            ok: function ($event) {
              _vm.successModalDelete = false
            },
          },
          model: {
            value: _vm.successModalDelete,
            callback: function ($$v) {
              _vm.successModalDelete = $$v
            },
            expression: "successModalDelete",
          },
        },
        [
          _vm._v(
            "\n\t\t" +
              _vm._s(this.FormMSG(24, "This accomodation has been deleted.")) +
              "\n\t"
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }